@import './src/root/globals.scss';

.answer-guidance-header {
  padding-bottom: 30px;
  background: $white;
}
.answer-box {
  padding: 20px;
  margin-top: 0.5rem;
  background-color: $white;

  &:last-of-type {
    margin-bottom: 4rem;
  }

  p {
    font-size: 1.6rem;
    // font-weight: $font-weight-medium;

    span {
      display: inline-block;
      margin-right: 10px;
    }
  }
  .input-table {
    display: none;
  }

  table {
    border: 0.5px solid $grey-10;
    width: 100%;
    border-radius: 5px;
    //overflow: hidden;
  }

  th {
    background: rgba(0, 0, 0, 0.05);
    border-right: 0.5px solid $grey-10;
    border-bottom: 0.5px solid $grey-10;
    font-weight: 700;
    text-align: left;
    text-indent: 15px;
    font-size: 1.3rem;
    padding: 10px 0;

    &:last-of-type {
      border-right: 0;
    }
  }

  tr {
    border: 0.5px solid $grey-10;
  }

  td {
    padding: 0;
    width: 50%;
    height: 10em;
    border-right: 0.5px solid $grey-10;
    position: relative;
    &:last-of-type {
      border-right: 0;
    }

    textarea {
      border: 0;
      border-radius: 0;
      margin-left: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      min-height: 120px;
      padding-bottom: 10px;
      font-style: normal;
      font-size: 1.4rem;
      line-height: 150%;
      font-family: 'Open Sans';

      &:disabled {
        background: $grey-5;
        color: $grey;
      }

      &.error {
        border-radius: 3px;
        border: 2px solid $error;
      }
    }
  }
}

.scenario-container.ags {
  opacity: 0.5;
}

.question {
  .quill {
    max-width: unset;
    width: 100%;
    min-height: 100%;
    height: 100%;
    padding: 0px;

    .ql-container.ql-snow {
      border: none;
    }

    .ql-editor {
      padding: 0;
      color: black;
      font-weight: 400;
      font-size: 17px;

      p {
        font-size: inherit;
      }
    }
  }
}
.question-title {
  display: flex;
  padding: 0.5rem 0 1.5rem 0;
  justify-content: space-between;

  .title {
    font-weight: $font-weight-medium;
    font-size: 1.4rem;
    flex: 1;
    display: flex;
    div.prefix {
      margin-right: 1rem;
    }
  }

  .marks {
    padding-left: 30px;
    color: $primary-color;
    font-size: 1.4rem;
    font-style: italic;
    margin-right: 5px;
  }
}

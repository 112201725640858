@import './src/root/globals.scss';

.dashboard-info-container {
  padding-top: 20px;
  padding-bottom: 20px;
}

.response {
  h1 {
    font-size: 1.8rem;
    padding: 0 1rem;
    text-transform: uppercase;
  }

  h2 {
    margin: 0;
    padding: 3rem 2rem;
    background-color: $white;

    &.start-title {
      background: none;
      padding: 0;
      margin: 3.5rem 0 1rem 0;
    }
  }

  .download-pdf {
    margin-top: 3rem;
    display: flex;
    justify-content: flex-end;
  }

  .answer-guidance-header dl {
    padding-bottom: 0;
  }

  .start-screen {
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $grey-10;

    p {
      width: 64%;
      margin: 0 auto 3rem auto;
      text-align: center;
    }

    a {
      margin-top: 15px;
      font-size: 1.2rem;
      color: $grey-40;
    }
  }

  dl {
    display: grid;
    grid-template-columns: max-content auto;
    background-color: $white;
    margin: -10px 0 0 0;
    padding: 0 10px 25px 0;
    max-width: 930px;

    dt {
      grid-column-start: 1;
      margin: 1px;
      padding: 0.5rem 2rem;
      font-size: 1.5rem;
      font-weight: 800;
    }

    dd {
      grid-column-start: 2;
      font-size: 1.5rem;
      padding: 0.5rem 2rem;
    }
  }

  .mark {
    min-width: 930px;
  }

  .mark-row {
    display: flex;
    height: 100%;

    .short-container,
    .list-container,
    .fillable-container {
      min-width: 930px;
    }

    .clinical-container,
    .scenario-container {
      min-width: 930px;
    }
  }
  .total-marks {
    display: flex;
    justify-content: flex-end;
    color: $primary-color;
    font-weight: $font-weight-bold;
    font-size: 1.9rem;
    margin: 3.5rem 2rem -3.25rem 0;
    max-width: 930px;
  }

  &.marked {
    .prefix-row {
      margin-top: 1.75rem;
    }
    .mark-box {
      textarea {
        border: 0;
        padding: 0;
      }
    }
  }
}

.deadlines {
  width: 450px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  flex: 0.5;
  margin-left: 2rem;
  padding-left: 2rem;
  border-left: 1px solid $grey-10;
  padding-right: 10px;

  .deadline-picker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    > div:first-of-type {
      margin-right: 2rem;
      font-weight: $font-weight-bold;
      font-size: 1.5rem;
      flex: 0.5;
    }
    .react-datepicker-wrapper {
      flex: 0.5;
    }
  }
}

.comment {
  h4 {
    font-size: 1.4rem;
  }
  textarea {
    min-height: 100px;
    background: none;
    padding: 1.5rem;
    line-height: 140%;
  }
}

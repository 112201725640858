@import './src/root/globals.scss';

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.6) !important;
  z-index: 3;
}
.ReactModal__Content {
  border-radius: 5px !important;
  padding: 4rem !important;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);

  h3 {
    padding: 0 0 1.5rem 0;
  }

  .button-row {
    margin-top: 3rem;
  }
}

@import './msoa-colors.scss';

body {
  background: $grey-5;
}

$topbar-height: 90px;

$font-family: 'Open Sans', sans-serif;
$font-weight-thin: 300;
$font-weight-regular: 400;
$font-weight-medium: 600;
$font-weight-bold: 800;

// Mixins
@mixin transitionGeneral() {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.main-header {
  font-size: 1.2rem;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

.animated-input {
  position: relative;
  margin-top: 1rem;

  input {
    border: 0;
    border-bottom: 2px solid $border-color;
    padding: 2.5rem 0 1.2rem 0;
    width: 100%;
    font-size: 1.6rem;
    appearance: none;
    outline: none;
    @include transitionGeneral();
    &:focus {
      border-color: $border-color--focus;
    }
    &:focus + label,
    &.notempty + label {
      font-size: 1.2rem;
      bottom: 75%;
      color: $border-color--focus;
    }
  }

  label {
    @include transitionGeneral();
    position: absolute;
    bottom: 2.2rem;
    left: 0;
    pointer-events: none;
    font-size: 1.6rem;
    color: $border-color;
  }
}

// BASIC INPUT
input,
select,
textarea {
  @include transitionGeneral();
  border-radius: 3px;
  border: 2px solid $border-color;
  padding: 1rem 1.5rem;
  outline: none;
  appearance: none;
  width: 100%;
  line-height: 100%;
  resize: none;
  font-style: italic;

  &:focus {
    border-color: $border-color--focus;
  }
}

input[type='checkbox'] {
  background-color: $white;
  padding: 10px;
  margin: 0;
  border: 2px solid $grey-25;
  width: 8px;

  &:checked {
    background-color: $primary-color;
  }
}

span {
  @include transitionGeneral();
  font-family: $font-family;
}

p {
  font-size: 1.4rem;
}

// BUTTONS
a.button,
button,
input[type='submit'] {
  @include transitionGeneral();
  text-align: center;
  padding: 1.5rem 2rem;
  border: 0;
  line-height: 100%;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  width: auto;
  background-color: $grey-25;
  color: $grey-75;
  font-size: 1.4rem;
  font-weight: $font-weight-medium;
  border-radius: 3px;

  &.return {
    width: 100%;
    background-color: $primary-color;
    color: $white;
    margin-top: 6.75rem;
    margin-bottom: 5px;
    text-align: left;
  }

  &:hover {
    opacity: 0.7;
  }
}

.button-container {
  margin-top: 2.5rem;
  span {
    font-weight: $font-weight-medium;
    font-size: 1.4rem;
    margin-right: 2.5rem;
  }
  button.muted {
    background: $grey-10;
    color: $grey-50;
  }
  button.save {
    background: $primary-color;
    margin-left: 1rem;
    color: $white;
  }
}

h2 {
  margin: 3.5rem 0 1rem 0;
  font-size: 1.6rem;
}

h3 {
  padding: 2rem 0 1rem 2rem;
  margin: 0;
  color: $primary-color;
  border-radius: 3px 3px 0 0;
  background-color: $white;
}

h4 {
  color: $black;
  font-size: 1.2rem;
}

// Link styling properties
a {
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
}

.hidden {
  display: none;
}

// screen widths
$x-large: 85em;
$large: 68.75em;
$medium: 55.625em;
$smallmed: 46.875em;
$small: 36.25em;

@import '../../root/globals.scss';

div.select__control {
  border: 2px solid #d4d4d4;
  border-color: $primary-color;

  &--is-focused {
    background-color: $white;
    border-color: $primary-color;
    box-shadow: none;
  }

  &:hover {
    border-color: $primary-color;
  }

  .select__value-container {
    padding: 0 0;
    overflow: visible;

    .select__multi-value__label {
      padding: 8px;
      margin-left: 0;
      font-size: 12px;
    }

    .select__single-value {
      font-size: 1.4rem;
      margin-left: 1rem;
    }

    .select__input {
      margin-left: 1rem;
    }
  }

  .select__indicators {
    background-color: $primary-color;
    svg path {
      fill: $white !important;
    }

    .select__indicator {
      padding-right: 0.75rem;
    }
    .select__indicator-separator {
      display: none;
    }
  }
}

.select__menu {
  .select__menu-list {
    margin: 0;
    padding: 0;

    .select__option {
      &--is-selected {
        background-color: $primary-color;
      }
    }
  }
}

.select__placeholder {
  font-style: italic;
  padding-left: 1rem;
  font-weight: 400;
  font-size: 13.33333px;
  margin-left: 0 !important;
}

.user-select {
  min-width: 500px;
  width: 100%;
}

* {
  box-sizing: border-box;
}

/* Adjust font sizes for ease of use with REM ie. 1rem = 10px / 1.6rem = 16px */
html {
  font-size: 62.5%;
}

/* Scale fonts off body baseline */
body {
  margin: 0;
  padding: 0;
  font-size: 1.6em;
  font-family: 'Open Sans', Sans-Serif;
}

@import '../../root/globals.scss';

@mixin form-status {
  position: relative;
  margin: 0 auto 2.5rem auto;
  padding: 1.5rem;
  border-radius: 3px;
  text-align: center;
  color: $white;
  width: calc(100% - 4rem);
  font-size: 1.4rem !important;
  font-family: 'Open Sans', Sans-Serif;
  position: sticky;
  top: 0;
  z-index: 400;

  &:empty {
    display: none;
  }
}

div.form-status {
  @include form-status;
  background-color: $green;
}

div.hide-status {
  visibility: hidden;
}

div.form-status.error {
  @include form-status;
  background-color: $error;
}

div.closable-form-status.error {
  @include form-status;
  display: flex;
  flex-direction: row-reverse;
  background-color: $error;

  .message {
    flex: 1;
  }

  .close-button {
    cursor: pointer;
  }
}

.react-tabs div.form-status,
.response .form-status {
  @include form-status;
  width: 100% !important;
}

.content-pane .form-status,
.content-pane .form-status.error {
  @include form-status;
  width: 100%;
  margin-top: 2rem;
}

.user-pane-container .form-status,
.user-pane-container .form-status.error,
.edit-group .form-status,
.edit-group .form-status.error {
  @include form-status;
}

.content-pane .whitebg .form-status {
  @include form-status;
  margin-top: 1rem;
}

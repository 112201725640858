@import './src/root/globals.scss';

.question-picker-container {
  padding: 30px;
  margin-top: 0.5rem;
  min-height: 150px;
  background-color: $white;
  color: $grey-40;
  margin-bottom: 1rem;

  .question-picker {
    display: flex;
    padding: 30px;
    transition: box-shadow 0.5s;
    border: 1px dashed $grey-10;
    border-radius: 5px;
    cursor: pointer;

    .icon {
      width: 52px;
      height: 48px;
      border-radius: 100%;
      background: $primary-color;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      svg path {
        fill: white;
        width: 24px;
        height: 24px;
      }
    }

    .toggled-text {
      padding-left: 30px;
    }

    &.toggled {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 30px;
      transition: box-shadow 0.5s;
    }
  }

  div {
    align-items: center;
  }

  .button-row {
    display: flex;
    justify-content: flex-start;
    margin-left: 0;
    font-style: italic;
    width: 100%;
    height: 70px;

    .button-container {
      height: 100%;
      background-color: $white;
      margin: 0;
      padding: 0;
      flex: 1;

      img {
        height: 40px;
        width: 40px;
      }

      div {
        font-size: 1.2rem;
        margin-top: 8px;
      }
    }
  }
}

.question-container {
  display: block;
  height: 150px;
  margin: 5px 0;
  padding: 20px;
  background-color: $white;
}

.scenario-container {
  padding: 10px 20px;

  &.active {
    border-left: 6px solid #41489e;
    margin-left: -0.6rem;
    padding: 20px 20px 10px 20px;
    margin-bottom: -5px;
  }

  .scenario-static {
    border: none;
    border-radius: 0;
    background: $grey-5;
    box-sizing: border-box;
    color: $primary-color;
    font-family: $font-family;
    font-weight: $font-weight-bold;
    font-style: normal;
    padding-left: 0;
    padding-bottom: 1rem;
    padding-right: 0;
    line-height: 150%;
    font-size: 1.4rem;
  }

  &.error {
    border-radius: 3px;
    border: 2px solid $error;
  }

  // rich text styles
  .quill {
    max-width: unset;
    padding-left: 0;
    padding-right: 0;

    .ql-container.ql-snow {
      border: none;
    }
    .ql-toolbar {
      margin-bottom: 0.5em;
    }
    .ql-editor {
      padding: 0;
      color: $primary-color;
      border-bottom: 2px solid #d4d4d4;
    }
    .ql-editor:focus {
      border-bottom: 2px solid #41489e;
    }
    .ql-disabled {
      .ql-editor {
        border-bottom: 0px;
      }
    }
  }

  &.active .button-row {
    padding: 10px 0;
  }
  &:not(.active) .button-row {
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    padding: 0;
  }
  &:not(.active):not(.crq-submitted):hover .button-row {
    max-height: 75px;
  }
}

.short-container,
.clinical-container,
.list-container,
.fillable-container {
  padding: 20px;
  background: $white;
  margin: 5px 0;

  &.active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 5px;
    border-left: 6px solid #41489e;
    margin-left: -0.6rem;
    padding: 20px 20px 10px 20px;
  }

  .quill {
    max-width: unset;
    width: 100%;
    padding-left: 0.5em;
    padding-top: 0.5em;
    padding-right: 0;
    overflow: visible;

    .ql-container.ql-snow {
      border: none;
    }
    .ql-toolbar {
      margin-bottom: 0.5em;
    }
    .ql-editor {
      padding: 0;
      color: black;
      height: max-content;
      border-bottom: 2px solid #d4d4d4;
      font-size: 17px;

      p {
        font-size: inherit;
      }
    }
    .ql-editor:focus {
      border-bottom: 2px solid #41489e;
    }
    .ql-disabled {
      .ql-editor {
        border-bottom: 0px;
      }
    }
  }
  .ql-editor.ql-blank::before {
    // set placeholder
    font-size: 14px;
    font-style: italic;
    color: #b1b1b1;
    font-weight: 400;
  }
  &.error {
    border-radius: 3px;
    border: 2px solid $error;
  }

  .item-title {
    font-weight: $font-weight-medium;
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    &:empty {
      display: none;
    }
  }

  .input-mark-container {
    margin-bottom: 1.5rem;

    .static {
      width: 100%;
    }

    .prefix {
      margin-right: 1rem;
      padding-top: 1rem;
      font-weight: 700;
      font-size: 1.4rem;

      &::after {
        content: ')';
      }
    }
  }

  textarea {
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    color: $black;
    font-family: $font-family;
    border-bottom: 2px solid #d4d4d4;
    font-style: normal;
    padding-left: 0;
    padding-bottom: 1rem;
    padding-right: 0;
    margin-right: 2.5rem;
    line-height: 150%;
    overflow: hidden !important;

    &[disabled] {
      border-bottom: none;
      height: 100%;
      background: $white;
      color: $black;
    }
  }

  input {
    border: 0;
    border-bottom: 2px solid #d4d4d4;
    border-radius: 0;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px;
    margin-right: 2rem;
    font-size: 1.6rem;
    font-weight: 600;
    font-style: normal;

    &::-webkit-input-placeholder {
      color: #b1b1b1;
      font-style: italic;
    }
    &::-moz-placeholder {
      color: #b1b1b1;
      font-style: italic;
    }
    &:-ms-input-placeholder {
      color: #b1b1b1;
      font-style: italic;
    }
    &:-moz-placeholder {
      color: #b1b1b1;
      font-style: italic;
    }
  }
  input[disabled] {
    background: $white;
    border-bottom: 2px solid transparent;

    &::-webkit-input-placeholder {
      color: $black;
      font-style: normal;
    }
    &::-moz-placeholder {
      color: $black;
      font-style: normal;
    }
    &:-ms-input-placeholder {
      color: $black;
      font-style: normal;
    }
    &:-moz-placeholder {
      color: $black;
      font-style: normal;
    }
  }

  input[name='userAnswer'] {
    &[disabled] {
      &::-webkit-input-placeholder {
        color: #b1b1b1;
        font-style: normal;
        font-weight: 400;
      }
      &::-moz-placeholder {
        color: #b1b1b1;
        font-style: normal;
        font-weight: 400;
      }
      &:-ms-input-placeholder {
        color: #b1b1b1;
        font-style: normal;
        font-weight: 400;
      }
      &:-moz-placeholder {
        color: #b1b1b1;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
  .button-row {
    padding: 10px 0;
  }

  &:not(.active) .button-row {
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
  &:not(.active):not(.crq-submitted):hover .button-row {
    max-height: 75px;
  }

  .marks {
    width: 100px;
    color: $primary-color;
    text-align: right;
    font-size: 1.4rem;
    font-style: italic;
    padding-top: 1rem;
    margin-right: 5px;
  }
}

.short-container .answer-field textarea {
  min-height: 150px;
}

.clinical-container {
  padding-bottom: 0;

  .input-table {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  .clinical-options {
    display: flex;
    padding: 2rem 3rem;
    margin-bottom: 1.5rem;
    border: 1px dashed $grey-10;
    border-radius: 5px;
    justify-content: center;

    .button-container {
      height: 100%;
      background-color: $white;
      margin: 0;
      padding: 1rem 4rem;

      img {
        height: 40px;
        width: 40px;
      }

      div {
        font-size: 1.2rem;
        margin-top: 8px;
      }
    }
  }
}

.add-answer-box {
  display: flex;
  width: 100%;
  border-radius: 3px;
  background: $white;
  font-weight: $font-weight-medium;
  color: $primary-color;
  border: 1px solid $grey-10;
  text-align: left;
  font-style: italic;

  .plus {
    margin-right: 1rem;
    color: $primary-color;
    font-weight: $font-weight-medium;
    font-size: 2rem;
  }
}

.input-mark-container {
  display: flex;
}

.mark-dropdown {
  padding: 5px;
  max-height: 3em;
  width: 150px;
}

.tab-content div:hover {
  .divide-picker {
    opacity: 1;
    max-height: 100px;
    margin: 1rem 0;
  }
}

.tab-content {
  position: relative;
}

.divide-picker {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;

  .dotted {
    border-bottom: 2px dotted $grey-25;
    height: 1px;
    width: 450px;
  }

  button {
    background: none;
    border: 2px solid $grey-25;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    appearance: none;
    outline: none;
    padding: 0;

    svg path {
      fill: $grey-25;
    }
  }
}

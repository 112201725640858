@import '../root/globals.scss';

.attachment {
  outline: none;
  margin: 2rem 0;

  .upload-dropzone {
    display: flex;
    text-align: center;
    width: 100%;
    cursor: grabbing;

    &:hover {
      background: #eeeeee;
    }

    p {
      margin: 20px 0 0 0;
    }

    > div {
      display: none;
      &:first-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .element {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .dropper {
    margin: auto;
    padding: 3rem 2rem;
    border: 1px dashed $grey-25;
    transition: border-color 0.3s ease-in-out;
    border-radius: 5px;
    background: $white;
    &:hover {
      border-color: $primary-color;
    }
    img {
      display: block;
      width: 42px;
      height: 42px;
      margin: 0 auto;
    }
  }

  .upload-complete {
    position: relative;
    border: 0;
    background: none;
    padding: 0;
    border-radius: 5px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      max-width: 650px;
    }
    .remove-upload {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;

      svg {
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: all 0.3s ease-in-out;

        path {
          fill: $white;
        }
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0);
        transition: all 0.3s ease-in-out;
      }

      &::after {
        content: 'Remove Image';
        display: block;
        color: white;
        position: absolute;
        width: 100%;
        bottom: 40%;
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        svg {
          opacity: 1;
        }
        &::after {
          opacity: 1;
        }
        &::before {
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }

    + .element {
      display: none;
    }
  }
}

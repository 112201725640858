@import './src/root/globals.scss';
@import './src/common/Select/react-select.scss';

.mark-box {
  background: #eaeaea;
  margin: 5px 0;
  padding: 1rem 1.5rem;
  min-width: 250px;
  top: 0;
  left: 100%;
  display: flex;
  flex-direction: column;

  .prefix-row {
    display: flex;
    align-items: center;
    margin-top: 0.8rem;

    .prefix {
      font-size: 1.4rem;
      font-weight: 800;
      flex: 5;
    }
  }

  .mark-dropdown {
    padding: 0;
    width: 220px;
    flex: 6;
  }

  .mark-total {
    color: $primary-color;
    font-style: italic;
    font-size: 1.6rem;
    margin-right: 5px;
    font-weight: $font-weight-bold;
  }

  textarea {
    padding: 1.5rem;
    flex: 1;
    background: #eaeaea;
    height: 100%;
    font-family: 'Open Sans';
    font-style: normal;
    line-height: 120%;
    margin-top: 2.5rem;
    font-size: 1.4rem;
    font-style: italic;
  }
}

@import './src/root/globals.scss';

.marks-index {
  .list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 3px;
    margin: 3px 0;
    min-height: 50px;
    border-radius: 3px;
    width: 100%;
    color: #ffffff;
    background: $primary-color;
    font-size: 1.4rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    .title {
      text-indent: 15px;
      width: 80%;
    }

    .total-marks {
      margin-left: auto;
      margin-right: 15px;
      font-weight: $font-weight-bold;
    }

    &:hover {
      background-color: $primary-color-80;
    }
  }
}

.table-background {
  width: 100%;
  border-collapse: collapse;

  .button-cell {
    padding-top: unset;
    display: flex;
    flex-direction: row-reverse;
    color: #ffffec;
    font-weight: 400;

    .unselected {
      padding-top: 0.6em;
      min-width: 7em;
      background-color: #41489e;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
    }
    .selected {
      padding-top: 0.6em;
      cursor: pointer;
      min-width: 7em;
      background-color: #57b767;
      border-radius: 4px;
      text-align: center;
    }
  }
  td {
    padding-left: 0.5em;
    padding-top: 0.5em;
    flex: 1;
    text-align: left;
  }
  tr {
    display: flex;
    min-height: 2.5em;
  }
  th {
    flex: 1;
    text-align: left;
    padding-left: 0.5em;
    background-color: white;
  }
}

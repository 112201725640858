@import '../../root/globals.scss';

div.login-container {
  font-family: $font-family;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
  background-color: $primary-color;

  &::before {
    content: '';
    background-image: url('../../root/assets/background.png');
    background-repeat: no-repeat;
    background-position: 28rem;
    background-size: 100%;
    width: 100vw;
    min-height: 100vh;
    transform: translateX(-25%);
  }

  @media screen and (max-width: 1023px) {
    &::before {
      transform: none;
      background-size: cover;
      background-position: -25px;
    }
    .login-form-container {
      right: auto;
      max-width: 360px;
      min-width: 0;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1360px) {
    &::before {
      background-size: cover;
    }
  }

  .change {
    font-size: 1.4rem;
    padding-top: 3rem;
    margin: auto;
    color: $primary-color;
    text-decoration: underline;
    span {
      cursor: pointer;
    }
  }

  .action-button {
    min-width: 120px;
    background-color: $secondary-color;
    color: $white;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: $font-weight-medium;
  }

  .fields {
    margin-bottom: 5rem;
    .field {
      padding: 0;
      input {
        padding: 1rem;
      }
    }
  }

  .login.fields,
  .change-password.fields {
    .field:first-of-type {
      margin-bottom: 2rem;
    }
  }

  h4 {
    // To avoid the logo moving when you change login <-> reset. Maybe there's a better way :/
    margin-top: 0.903rem;
  }

  .success {
    padding: 1.5rem;
    text-align: center;
    border-radius: 3px;
    font-size: 1.4rem;
    background-color: $green;
  }
}

div.login-form-container {
  position: absolute;
  flex-direction: column;
  display: flex;
  min-width: 400px;
  width: 400px;
  padding: 5rem;
  background: $white;
  right: calc(25% - 200px);
  justify-content: space-between;

  &.error {
    padding-top: 10rem;
  }

  div.form-status {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 0;
  }
}

div.login-image-container {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin-bottom: 5rem;
}

img.login-image-logo {
  width: 180px;
  height: auto;
}

@import '../../root/globals.scss';

.answer-title-wrap {
  position: relative;
}

div.field {
  align-items: center;
  display: flex;
  padding: 2rem;
  background-color: $white;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-size: 1.2rem;
  margin-bottom: 0.2rem;
  border-radius: 3px;

  &.createdby {
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 3.2rem;
    right: 2rem;
  }

  &.block {
    display: block;
  }

  &.thin {
    margin: 1px;
    padding: 0.1rem 2rem;
    border-radius: 3px;
    &.checkbox {
      margin-top: 1rem;
    }
  }

  &.static {
    min-height: 70px;
    padding: 2rem;
    background-color: $primary-color-80;
    color: $white;
  }

  &.large {
    border-bottom: 1px solid #f2f2f2;
    margin: 0;
  }

  &.meta {
    margin: 0;
    padding: 0.5rem 2rem;
    align-items: flex-start;
  }
}

label.field-label {
  min-width: 220px;
  font-weight: $font-weight-medium;
  font-size: 1.2rem;

  &.block {
    display: block;
  }
  &.meta {
    min-width: 120px;
    font-weight: $font-weight-bold;
    font-size: 1.5rem;
    margin-right: 2rem;
    margin-top: 3px;
  }
}

input {
  &.block {
    display: block;
  }

  &.large {
    min-height: 50px;
    border: none;
  }

  &.static {
    background-color: $primary-color-80;
    color: $white;
  }

  &.meta {
    background: none;
    border: none;
    padding: 3px;
    font-style: normal;
    color: $black;
    font-size: 1.5rem;
  }

  &.underline {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $primary-color;
    padding: 1rem 0;

    &:focus {
      border-bottom: 1px solid $secondary-color;
    }
  }
}

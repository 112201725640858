@import '../../root/globals.scss';

.react-datepicker {
  font-size: 1em;
  border: 2px solid $primary-color;
}

.react-datepicker__header {
  padding-top: 0.8em;
  background-color: $primary-color;
  border-radius: 0;
  color: $white;
}

.react-datepicker__day--selected {
  background-color: $primary-color;
}

.react-datepicker-time__header {
  font-size: 1em;
  color: $white;
}

.react-datepicker__month {
  margin: 0.4em 1em;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}

.react-datepicker__day {
  font-weight: 400;
  &.react-datepicker__day--outside-month {
    opacity: 0.5;
  }
}

.react-datepicker__day--today {
  color: $secondary-color;
  font-weight: 800;
}

.react-datepicker__current-month {
  color: $white;
  font-size: 1em;
}

.react-datepicker__day-name {
  color: $white;
}

.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.react-datepicker__time-container,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 125px;
  text-align: left;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: $primary-color;

  &:hover {
    background-color: $primary-color-80;
  }
}

@import './src/root/globals.scss';

.response-meta {
  background-color: $white;
  border-left: 6px solid $primary-color;
  padding: 25px 10px 25px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 930px;

  &.confirmed {
    border: 0;
    box-shadow: none;
    margin: 0;
    padding: 2rem 10px 10px 0;
  }
  textarea {
    line-height: 140%;
    min-height: 38px;
  }
  textarea[disabled] {
    background: none;
    border: none;
    font-size: 1.5rem;
    font-style: normal;
    padding: 3px;
    color: $black;
  }
}

.custom-response > div:first-of-type > div > .simple-button.muted {
  padding-right: 0;
}

.static-response-meta {
  .list {
    display: flex;
    flex-wrap: wrap;
  }
  .top {
    background-color: hsl(0,0%,90%);
    border-radius: 2px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
  }
  .bottom {
    border-radius: 2px;
    color: hsl(0,0%,20%);
    font-size: 85%;
    overflow: hidden;
    padding: 6px;
    text-overflow: ellipsis;
    box-sizing: border-box;
  }
}

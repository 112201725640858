@import '../../root/globals.scss';

nav {
  min-width: 220px;
  margin-top: 58px;
  padding: 10px 20px 0 0;
  height: 100%;

  a:first-of-type {
    border-radius: 3px 3px 0 0;
  }
  a:last-of-type {
    border-radius: 0 0 3px 3px;
  }
}

.queries {
  margin-top: 1rem;
  padding: 1.5rem;
  border-radius: 3px;
  background-color: $white;
  font-family: $font-family;

  h4 {
    color: $primary-color;
    font-size: 1.4rem;
    margin: 0 0 1rem 0;
  }
  a {
    margin: 5px 0;
    color: $black;
    &:hover {
      text-decoration: underline;
    }
  }
}

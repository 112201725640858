@import './src/root/globals.scss';

.new-group,
.edit-group {
  .user-dropdowns {
    padding: 0 10px;

    .accordion {
      .label {
        color: $black;
        border: 2px solid $grey-10;
        background-color: $white;
      }

      .section {
        background-color: $grey-10;
        border: 1px solid $grey-25;
      }
    }
  }
}

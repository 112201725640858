@import './src/root/globals.scss';

.dashboard-deadlines {
  .tab-group {
    .tabs {
      a {
        width: 100%;
        font-weight: $font-weight-bold;
      }
    }
  }
}

.deadline-index {
  padding: 15px 20px 30px 20px;
  height: 320px;
  overflow-y: auto;
  .notification {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    span:first-of-type {
      margin-bottom: 5px;
    }
    span:last-of-type {
      margin-bottom: 5px;
    }
  }
}

.notification {
  padding: 5px 0;
  border-bottom: 1px solid lightgrey;

  span:first-of-type {
    color: $primary-color;
    font-weight: $font-weight-bold;
    padding-right: 3px;
  }
}

.missed-deadline {
  padding: 0 5px 10px 5px;
  margin-bottom: 10px;
  border-bottom: 2px solid grey;
  color: $black;

  a {
    &:hover {
      opacity: 0.6;
    }
    &:visited {
      color: $black;
    }
  }

  span:nth-of-type(odd) {
    color: $primary-color;
    font-weight: $font-weight-bold;
  }

  p {
    margin: 0;
  }
}

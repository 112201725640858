@import './src/root/globals.scss';

.simple-row {
  display: flex;
  flex-direction: row;

  .simple-item {
    flex: 1;
    margin: 55px;
    display: flex;
    align-items: center;
    height: 100%;
    list-style-type: none;
    background-color: $grey-5;
    border-radius: 0 3px 3px 0;

    svg {
      height: 20px;
      width: 20px;
      margin: 0 10px;
      color: $black;
    }
  }
}

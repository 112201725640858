@import './src/root/globals.scss';
.accordion {
  border-radius: 1rem;
}

.accordionSegment {
  background-color: white;
  min-height: 3em;
  margin-top: 0.5em;
  padding-bottom: 0.5em;

  .accordionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 1em;
    padding-bottom: 1em;
    justify-content: space-between;
    padding-right: 0.5em;

    h2 {
      margin: 0;
      margin-left: 1em;
      color: rgba(0, 0, 0, 0.6);
    }

    FontAwesomeIcon {
      color: rgba(0, 0, 0, 0.6);
    }

    .paperSelector {
      width: 80%;
      display: flex;
      flex-direction: row;

      .paperSelector-paper {
        cursor: pointer;
      }
      .paperRadio {
        background-color: rgb(122, 127, 187);
        width: fit-content;
        padding: 0.5em;
        margin-left: 0.5em;
        border-radius: 3px;
      }
      .paperRadio:hover {
        background-color: rgb(157, 159, 196);
      }

      .selected {
        background-color: $primary-color;
        width: fit-content;
        padding: 0.5em;
        margin-left: 0.5em;
        border-radius: 3px;
      }
    }
  }
}

.roundSegment {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  border-width: 3px;
  margin-top: 0.25em;
  margin-left: 0.25em;

  .hidden {
    visibility: hidden;
  }

  .roundTitle {
    height: 2.75em;
    border-radius: 3px;
    flex: 9;
    background-color: rgb(65, 72, 158);
    display: flex;
    align-items: center;
    padding-left: 0.5em;
    margin-right: 0.5em;

    h2 {
      margin: 0;
      color: #fff;
      font-size: small;
    }
  }

  .roundButton {
    margin: 0;
    border-radius: 3px;
    padding: 10px;
    flex: 1;
    background-color: rgb(122, 127, 187);
    color: #fff;
    height: 2.75em;
    margin-right: 0.5em;
    text-align: center;
  }
  .roundButton:hover {
    background-color: rgb(157, 159, 196);
  }
}

.heading {
  color: rgba(0, 0, 0, 0.6);
}

@import '../../root/globals.scss';

.accordion {
  position: relative;
  margin: 2px 10px;
  padding: 0;
  color: $white;

  .label {
    display: flex;
    padding: 1.5rem;
    line-height: 1.1;
    height: 100%;
    background: $primary-color;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;

    &:hover {
      opacity: 0.8;
    }

    .cancel {
      margin-left: auto;
      margin-right: 1rem;
      padding-right: 1rem;
    }
    .index {
      border-right: 1px solid $grey-25;
      margin-left: 0;
      font-weight: $font-weight-bold;
      font-size: 1.3rem;
      padding-right: 15px;
    }
    .clear {
      justify-self: flex-end;
      position: relative;
      z-index: 2;
      width: 19px;
      height: 19px;
      background: $white;
      border-radius: 100%;
      z-index: 1;
      transition: all 0.3s ease-in-out;
      &::after {
        content: 'x';
        font-weight: $font-weight-bold;
        color: $primary-color;
        display: block;
        position: absolute;
        z-index: 2;
        top: -1px;
        right: 5px;
        font-size: 1.5rem;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    .text {
      text-transform: capitalize;
      text-align: left;
      flex: 1;
      margin-left: 15px;
      line-height: 140%;
    }
  }

  &.open .cancel {
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
  &.closed .cancel {
    display: none;
  }

  .accordion-section {
    position: absolute;
    z-index: 5;
    width: 100%;
    border: 1px solid $primary-color;
    border-top: 0;
    background: $white;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
    font-family: $font-family;

    .accordion-search {
      margin: 10px 0 10px 15px;
      width: 94.5%;
    }

    .list {
      max-height: 250px;
      overflow-y: auto;

      .list-item {
        display: flex;
        align-items: center;
        color: $black;
        font-size: 1.6rem;
        cursor: pointer;
        width: 100%;
        padding: 8px 20px;
        transition: all 0.3s ease-in-out;

        &:hover {
          background: #f0f0f0;
        }
        &:last-of-type {
          margin-bottom: 10px;
        }

        svg path {
          fill: $primary-color;
        }

        .title {
          display: flex;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-transform: capitalize;
          font-weight: 400;
          align-items: center;
          margin-left: 15px;

          .number {
            color: $grey-40;
            min-width: 120px;
            font-weight: $font-weight-thin;
            font-style: italic;
            font-size: 1.2rem;
            text-indent: 10px;
          }
        }
      }
    }
  }
}

.accordion-container[disabled],
.accordion-container > .accordion[disabled] {
  pointer-events: none;
}

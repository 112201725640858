$white: #ffffff;
$black: #000000;
$primary-color: #41489e;
$primary-color-80: lighten($primary-color, 20%);
$primary-color-60: lighten($primary-color, 40%);
$primary-color-50: lighten($primary-color, 50%);
$primary-color-25: lighten($primary-color, 75%);
$primary-color--dark: darken($primary-color, 25%);
$secondary-color: #cc6600;
$secondary-color-80: lighten($secondary-color, 20%);
$secondary-color-75: lighten($secondary-color, 25%);
$secondary-color-50: lighten($secondary-color, 50%);
$secondary-color-25: lighten($secondary-color, 75%);
$secondary-color--dark: darken($secondary-color, 100%);
$green: #57b767;
$grey: lighten(#212121, 30%);
$grey-75: lighten($black, 25%);
$grey-60: lighten($black, 40%);
$grey-50: lighten($black, 50%);
$grey-40: lighten($black, 60%);
$grey-25: lighten($black, 75%);
$grey-10: lighten($black, 90%);
$grey-5: lighten($black, 95%);
$border-color: lighten(#a1a1a1, 20%);
$border-color--focus: $primary-color;
$success: #458b25;
$warning: lighten($secondary-color, 25%);
$danger: #de1312;
$error: #ff3a64;

@import '../../root/globals.scss';

.link {
  padding: 1.5rem 1rem;
  color: $white;
  display: block;
  font-family: $font-family;
  font-size: 1.4rem;
  cursor: pointer;
  background-color: $primary-color;
  border-bottom: 1px solid $primary-color-80;
  text-decoration: none;

  &:hover {
    background-color: $primary-color-80;
  }
}

.navbar-group {
  margin-top: 1rem;
  margin-bottom: -1.5rem;
  a {
    padding: 1.5rem 1rem;
    border-radius: 0 !important;

    &:last-of-type {
      border-bottom: 0;
    }
  }
}

@import './src/root/globals.scss';

.input-table {
  display: flex;
  width: 100%;

  table {
    border-collapse: collapse;
    width: 97.5%; // add/remove button problems otherwise
  }

  tr {
    display: flex;
  }

  td {
    padding: 0;
    border: 1px solid #e7e7e7;
    flex: 1;
    min-height: 3em;
  }

  table {
    .quill {
      max-width: unset;
      width: 100%;
      min-height: 100%;
      height: 100%;
      padding: 0px;

      .ql-container.ql-snow {
        border: none;
      }

      .ql-editor {
        min-height: 2em;
        height: 100%;
        padding: 0;
        padding-left: 0.5em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        color: black;
        border: none;
        font-weight: unset;

        p {
          font-size: inherit;
        }
      }
      &.ql-show-toolbar {
        .ql-toolbar {
          display: block;
        }
      }
      .ql-toolbar {
        display: none;
        position: relative;
        z-index: 300;
        margin: 0px;
      }
      .ql-editor:focus {
        border-bottom: none;
      }
    }
  }

  tr:first-child {
    td {
      background: #f5f5f5;
    }
    textarea {
      background: transparent;
    }
  }

  tr:first-child,
  td:first-child {
    textarea {
      font-weight: 600;
      color: $black;
    }
  }

  tfoot {
    td {
      text-align: center;
      position: relative;
      height: 24px;
      background: none !important;
    }
    tr:first-of-type {
      opacity: 0;
      td {
        border: 0;
      }
    }
  }

  tbody:hover ~ tfoot tr:first-of-type,
  tfoot:hover tr:first-of-type {
    opacity: 1;
  }

  tr:first-of-type td.control {
    visibility: hidden;
    margin-right: 20px;
  }
  tr:hover td.control {
    opacity: 1;
  }
  td.control {
    border: 0;
    width: 30px;
    position: relative;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    flex: 0;
    margin: 0 20px;
  }

  .add-row {
    width: 100%;
  }

  .add-column,
  .add-row {
    background: #dedfec;
    padding: 5px;
    border-radius: 0;
    svg path {
      fill: $primary-color;
    }
  }
  .add-column {
    margin-bottom: 52.5px;
  }

  .delete-row,
  .delete-column {
    border-radius: 0;
    background: white;
    border: 1px solid #d4d4d4;
    width: 24px;
    height: 24px;
    padding: 6px;
    border-radius: 100%;
    position: absolute;
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      path {
        fill: #d4d4d4;
        transition: all 0.3s ease-in-out;
      }
    }
    &:hover {
      background: $primary-color;
      opacity: 1;
      svg path {
        fill: white;
      }
    }
  }
  .delete-row {
    top: 50%;
    margin-top: -12px;
    left: -13px;
  }
  .delete-column {
    left: 50%;
    margin-left: -12px;
    top: -13px;
  }
}

@import './src/root/globals.scss';

button {
  font-family: $font-family;
}
.simple-button {
  font-family: $font-family;
  border-radius: 5px;
  padding: 15px 30px;
  cursor: pointer;
  font-weight: 900;
  font-size: 1.2rem;
  text-transform: uppercase;

  // Default theme:
  border: none;
  background: $primary-color;
  color: #fff;
  outline: none;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.save {
    background: none;
    color: $primary-color;
    font-weight: $font-weight-medium;
    padding: 15px;
  }

  &.muted {
    background: none;
    color: $grey-25;
    font-weight: $font-weight-medium;
    padding: 15px;
  }

  &.muted-white {
    background: none;
    color: $white;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }

  &.danger {
    color: $danger;
  }

  &.delete {
    background-color: $danger;
  }

  &.default {
    padding: 0.5rem;
    background: none;
    color: $primary-color;
  }

  &.page {
    background: none;
    color: $primary-color;
    padding: 1rem 1.5rem;
  }

  &.page-focus {
    background: $primary-color;
    color: $white;
    padding: 1rem 1.5rem;
  }

  &.green {
    background: $green;
    color: $white;
  }

  &.time {
    display: flex;
    justify-content: space-between;

    > * {
      margin: 0 5px;
    }

    :nth-child(1) {
      font-size: 1.3rem;
    }
    :nth-child(2) {
      margin: 0 0 0 10px;
    }
  }

  &.nav {
    width: 100%;
    padding: 1.5rem 1rem;
    font-size: 1.4rem;
    font-weight: $font-weight-thin;
    border-radius: 0;
    border-bottom: 1px solid #7c82c9;
    text-align: left;
    text-transform: none;
  }
}

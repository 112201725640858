@import './src/root/globals.scss';

.tab-group {
  h4 {
    font-size: 1.4rem;
  }
  > .tabs {
    display: flex;
    max-width: 930px;

    > .tab {
      display: block;
      padding: 8px 20px;
      outline: 0;
      color: $black;
      background: $grey-5;
      text-decoration: none;
      border-bottom: 5px solid $grey-10;

      &:focus,
      &.active {
        border-bottom: 5px solid $primary-color;
      }
      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
      &.disabled.active {
        opacity: 1;
      }
    }
  }
  + span {
    display: flex;
    justify-content: flex-end;
    > button {
      margin-top: 2rem;
    }
  }
}

.dashboard-deadlines .tab-group {
  > .tabs {
    > .tab {
      border-bottom: 2px solid $grey-10;
      text-align: center;
      text-transform: uppercase;
      background: $white;
      &:focus,
      &.active {
        border-bottom: 2px solid $primary-color;
      }
    }
  }
}

@import '../../root/globals.scss';

div.content-pane {
  font-family: $font-family;
  flex: 6;
  margin-bottom: 4rem;

  h2 {
    max-width: 930px;

    &:empty {
      display: none;
    }
  }

  .add-section {
    display: flex;
    background-color: $white;
    align-items: center;
    padding: 1.5rem 2.5rem;
    font-weight: $font-weight-medium;
    font-size: 1.4rem;
    border-radius: 3px;
    cursor: pointer;

    .icon {
      width: 28px;
      height: 28px;
      border-radius: 100%;
      background: $primary-color;
      color: white;
      text-align: center;
      line-height: 26px;
      font-size: 2.1rem;
      margin-right: 2rem;
    }

    svg {
      margin-right: 10px;
      padding: 0;
      color: $white;
      background-color: $white;
      height: 20px;
      width: 20px;
      color: $primary-color;
    }
  }

  .search {
    background-color: $white;
    padding: 20px;
    margin: 0;
  }

  .simple-index {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .simple-item {
    display: flex;
    padding: 5px 0 5px 0;
    margin: 2px 0;
    background-color: $white;
  }
}

@import '../../root/globals.scss';
@import '../Select/react-select.scss';

.index-table {
  overflow-x: auto;
  display: grid;
  margin-top: 10px;
  padding: 2.5rem;
  background-color: $white;
  border-radius: 3px;
  font-size: 1.4rem;
}

.index-table-search {
  position: relative;
  margin-right: 15px;
}

.index-table-control-row {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border-radius: 3px;
}

.control-row-left {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.control-row-page-select {
  min-width: 90px;
  div {
    cursor: pointer;
  }
  div.select__indicator {
    padding-right: 8px;
  }
  div.select__control {
    padding-left: 5px;
  }
  div.select__single-value {
    margin-left: 2px;
  }
}

.index-table-paging-row {
  max-width: 71vw;
  margin: 2em 0;
  display: flex;
}

.index-table-header {
  display: flex;
  margin: 2.5rem 0 0.5rem 0;
  padding: 0;

  span {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
    font-size: 1.3rem;
    margin-left: -2.8rem;

    &:first-of-type {
      padding: 0 0 0 0.5em;
      margin-left: 0;
    }
  }
}

.index-table-row {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
  border-radius: 3px;
  color: $black;

  &:hover {
    span.index-table-row-cell {
      background-color: $primary-color-60;
    }
  }

  > a {
    all: unset;
    cursor: pointer;
    display: flex;
    width: 100%;
  }
}

.index-table-row-cell {
  &:first-of-type {
    border-radius: 3px 0 0 3px;
  }
  &:last-of-type {
    border-radius: 0 3px 3px 0;
  }
  &:empty {
  }
}

.index-table-row-action {
  display: flex;
  padding: 0.6rem 1.05rem 1.7rem 1.05rem;
  margin: 0 5px 0 5px;
  border-radius: 2px;
  background-color: $grey-50;
  color: $white;
  text-decoration: none;
  font-size: 1.7rem;

  &:hover {
    background-color: $primary-color;
  }
}

.index-table-row-cell {
  flex: 1;
  overflow: hidden;
  min-height: 4.1rem;
  white-space: nowrap;
  padding-right: 2em;
  padding: 0.75em;
  text-overflow: ellipsis;
  background-color: $grey-5;
}

.index-table-info {
  font-size: 10pt;
  margin: auto 0 auto 1rem;
  color: $grey;
}

.index-table-control {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  white-space: nowrap;

  span {
    border-radius: 3px;
    padding: 0.3em 0.6em;
    margin: 0 0.2em;
    cursor: pointer;
  }

  svg {
    cursor: pointer;
  }
}

.user-blocked {
  border-left: 5px solid $danger;
}

@import './src/root/globals.scss';

.answer-field {
  display: flex;
  align-items: center;
  min-height: 50px;
  margin: 2px 0;
  border: 1px solid $grey-10;
  border-radius: 3px;

  textarea {
    flex: 11;
    font-style: normal;
    color: $black;
    font-size: 1.4rem;
    font-weight: 400;
    border: none !important;
    padding-left: 15px;

    &[disabled] {
      line-height: 30px;
    }

    &::-webkit-input-placeholder {
      color: #b9b9b9 !important;
      font-style: italic;
    }
    &::-moz-placeholder {
      color: #b9b9b9 !important;
      font-style: italic;
    }
    &:-ms-input-placeholder {
      color: #b9b9b9 !important;
      font-style: italic;
    }
    &:-moz-placeholder {
      color: #b9b9b9 !important;
      font-style: italic;
    }
  }

  .static {
    flex: 11;
    padding: 10px;
    font-style: normal;
    color: $black;
    font-size: 1.4rem;
    border: none !important;
    padding-left: 15px;

    &[disabled] {
      line-height: 30px;
    }

    &::-webkit-input-placeholder {
      color: #b9b9b9 !important;
      font-style: italic;
    }
    &::-moz-placeholder {
      color: #b9b9b9 !important;
      font-style: italic;
    }
    &:-ms-input-placeholder {
      color: #b9b9b9 !important;
      font-style: italic;
    }
    &:-moz-placeholder {
      color: #b9b9b9 !important;
      font-style: italic;
    }
  }

  .prefix {
    flex: 0.5;
    margin: -5px auto;
    padding-left: 20px;
    font-weight: 700;
    font-size: 1.4rem;
    border-right: 1px solid #d4d4d4;
    flex: 0.4 1 !important;
    margin-right: 0.5rem !important;
  }
}

.response.mark {
  min-width: 930px;
  .answer-field {
    input,
    textarea {
      &::-webkit-input-placeholder {
        color: $black;
      }
      &::-moz-placeholder {
        color: $black;
      }
      &:-ms-input-placeholder {
        color: $black;
      }
      &:-moz-placeholder {
        color: $black;
      }
    }
  }
}

@import './src/root/globals.scss';

.create-crq-container {
  margin-top: 3rem;
  .body {
    background: white;
    padding: 2rem;
    h2 {
      font-size: 2.1rem;
      margin: 1rem 0 3rem 0;
    }
  }
}
.response-pane {
  padding: 0 1.5rem 2rem 1.5rem;
  &:empty {
    display: none;
  }
}

.topic-bar {
  display: flex;
  justify-content: center;
  padding: 3px;
  color: $white;
  min-height: 60px;
  border-radius: 3px;
  font-size: 1.4rem;
  width: 100%;
  justify-content: flex-start;

  .nextBtnText {
    padding-right: 1em;
    font-style: normal;
    font-weight: bold;
  }
  &:hover {
    cursor: pointer;
  }

  // REMOVE THIS ONCE BUILT
  &.userCRQ {
    .topic-prefix,
    .topic-title {
      background-color: $primary-color;
    }
  }

  .topic {
    width: 90%;
  }
  .attachment-link {
    width: 10%;
    margin-right: 1.5rem;
    margin-left: 3rem;
    width: 26px;
    cursor: pointer;
    img {
      display: block;
      width: 30px;
    }
  }
}

.topic-prefix {
  background-color: $primary-color-80;
  display: flex;
  align-items: center;
  padding: 0 15px;
  text-align: center;
  border-right: 1px solid $white;
  border-radius: 3px 0 0 3px;
}

.topic-title {
  display: flex;
  align-items: center;
  background-color: $primary-color-80;
  padding-left: 15px;
  border-right: 1px solid $white;
  flex: 1 auto;
  font-style: italic;
  border-radius: 0 3px 3px 0;
  margin-right: 0.6rem;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.topic-link {
  min-width: 90px;
  padding: 1.7rem 0.5rem;
  background-color: $primary-color;
  color: $white;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;

  &.stage-unstarted-CRQ {
    background-color: $danger;
  }
  &.stage-substitute-CRQ {
    background-color: $danger;
  }
  &.stage-completed-CRQ {
    background-color: $green;
  }
  &.stage-in-progress-CRQ {
    background-color: $warning;
  }
  &:empty {
    background: $grey-10;
  }
}

.tab-list {
  background-color: $grey-5;
}

.round-date {
  display: flex;
  font-weight: $font-weight-bold;
  text-align: left;
  padding: 5px;
  width: 25%;
  .simple-button {
    text-align: left;
    padding: 0;
    margin-top: 10px;
  }
  .title-with-arrows {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title {
      padding-top: 5px;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .arrows {
      width: 10%;
      margin: auto;
      font-size: large;
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }
  }
}

.crq-guidance {
  margin-top: 20px;
  padding: 3rem 15px 4rem 15px;
  font-size: 1.4rem;
  li {
    padding: 0.25rem 0;
  }
}

.empty-divider {
  flex-grow: 1;
}

.substitute-button {
  margin-right: 5px;
  background-color: #f5f5f5;
  border-radius: 0px;
  color: #454ca0;
  height: 3em;
}
.substitute-item {
  flex: 1;
  margin-right: 3px;
  display: flex;
  align-items: center;
  height: 100%;
  list-style-type: none;
}

.mark-tab-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    flex: 1;
  }
  h6 {
    flex: 0.1;
    text-align: right;
    margin: 0;
  }
}

.release-papers-box {
  width: 10px;
  height: 10px;
  align-self: center;
}

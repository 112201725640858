@import './src/root/globals.scss';

.timer {
  position: absolute;
  height: 100vh;
  width: 200px;
  left: 0;
  top: 400px;

  &.admin {
    top: 450px;
  }

  .contents {
    position: sticky;
    top: 10px;
    left: 0;

    height: 80px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #ec9e38;
    border-radius: 3px;
    color: $white;

    .text {
      font-size: 1.2rem;
      padding-left: 15px;
    }

    .time {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 120px;
      background-color: $secondary-color;
      border-radius: 0 3px 3px 0;
      font-size: 3rem;
    }
  }
  .timeup {
    margin: 0 auto 0 auto;
    padding-left: 0;
  }
}

@import '../root/globals.scss';

.container {
  max-width: 1150px;
  width: 90%;
  margin: 0 auto;
}

.layout-under-topbar {
  position: relative;
  max-width: 1150px;
  width: 90%;
  margin: 0 auto;
  flex-direction: row;
  display: flex;
  min-height: calc(100vh - #{$topbar-height});
  height: calc(100% - #{$topbar-height});

  @media screen and (max-width: 1700px) {
    .response.mark {
      max-width: 750px;
    }
  }
}

.layout-barless-page {
  flex: 1;
  padding: 0;
  max-width: unset;
  width: 100%;
}

.layout-content-pane {
  flex: 8;
  padding: 1% 0 0 0;

  @media (max-width: $x-large) {
    padding: 1% 0 0 0;
  }
}

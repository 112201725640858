@import '../root/globals.scss';

.current-round-top-section {
  padding: 2rem 2rem 0 2rem;
  background-color: $white;
}

.current-round-papers {
  display: flex;
  align-items: center;

  & > .current-round-start-date {
    width: max-content;
    flex: 1 1 max-content;
  }

  // FIXME this causes horizontal scorlling on smaller resolutions
  & > .papers-list {
    max-width: 800px;
    margin-right: 1px;
  }
}

.current-round-title {
  text-transform: uppercase;
}

.current-round-start-date {
  font-weight: bold;
  padding-right: 5rem;
}

.current-round-table-container {
  > h1 {
    font-size: 1em;
    margin: 0;
    padding: 2rem 3px;
  }

  background-color: $white;
  padding: 1rem 20px;
}

.current-round-table {
  a.disabled {
    pointer-events: none;
    background-color: lightgrey;
    color: grey;
  }
}

.current-round-tabs-container {
  margin-top: 1rem;
}

.current-round-no-data {
  padding-left: 3px;
}

.cursor-default {
  cursor: default !important;
}

.current-round-status-selector {
  background-color: $white;
  margin: 1.5rem 0;
  padding: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.current-round-status-radio {
  background-color: #eee;
  color: grey;
  font-size: 1.2rem;
  padding: 1rem 1.5rem;
  border-radius: 3px;
  cursor: pointer;

  &.active {
    background-color: #3f4699;
    color: $white;
  }
}

.topic-link.stage-unstarted-CRQ {
  background-color: #3f4699;
}

.current-round-loading {
  .loading-spinner {
    display: flex;
    padding-top: 100px;
    img {
      margin: auto;
    }
  }
}

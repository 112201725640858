@import './src/root/globals.scss';
.background {
  flex: 1;

  .visible-background {
    background-color: white;
  }

  .inner-background {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1.5em;
  }
  .back-button {
    margin-top: 0px;
  }
  h3 {
    color: black;
  }
  h4 {
    color: black;
  }
}
.default_header {
  text-align: left;
  padding-left: 10px;
}
.name-row {
  flex: 1;
  display: flex;
  color: #f3f4fa;

  .number-line {
    background-color: #7c82c9;
    margin-right: 1px;
    padding-left: 0.5em;
    padding-right: 0.5em;
    height: 2em;
    line-height: 2em;
  }
  .name-line {
    background-color: #7c82c9;
    padding-left: 0.5em;
    height: 2em;
    line-height: 2em;
    flex: 1;
  }
}
.article-row {
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  list-style-type: none;
  border-radius: 0 3px 3px 0;

  h4 {
    padding-right: 0.5em;
  }
}
.buttom-row {
  flex: flex-end;
}
.bottom-button-row {
  flex: 1;
  flex-flow: row-reverse;
  display: flex;
  align-items: center;
  height: 100%;
  list-style-type: none;
  border-radius: 0 3px 3px 0;
}
.ok {
  background-color: transparent;
}
.cancel {
  background-color: transparent;
  color: #b2b6e1;
  margin: 1em;
}

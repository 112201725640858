@import './src/root/globals.scss';
.submission-background {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  > p.submission-description {
    text-align: left;
  }

  .medium-header {
    font-weight: 700;
    font-size: 1.7rem;
    text-align: left;
    color: $primary-color;
  }

  .next-unfinished-btn,
  .close-modal-btn {
    all: unset;
    width: 6em;
    text-align: center;
    padding: 0.75rem 0;
    border-radius: 3px;
    cursor: pointer;
  }

  .next-unfinished-btn {
    background-color: $primary-color;
    color: $white;
  }

  .close-modal-btn {
    background-color: $grey-10;
    color: $grey;
  }
}

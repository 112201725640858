@import './src/root/globals.scss';

.new-group,
.edit-group {
  border-radius: 3px;

  .edit,
  .new {
    background: $white;
    padding-top: 10px;
    padding-bottom: 20px;

    > div {
      min-width: 100px;
      padding: 2rem 2rem 0 2rem;
      font-weight: 600;
      font-size: 1.2rem;
    }

    div.field {
      padding-bottom: 15px;
    }
    div.form-status {
      padding: 1.5rem;
    }

    > * label.thin {
      min-width: 100px;
    }
  }

  div.field {
    &:first-of-type {
      padding-top: 2rem;
      padding-bottom: 0;
    }
  }

  .user-dropdowns {
    margin-top: 1rem;
    padding-top: 0 !important;
  }
  .accordion {
    margin: 2px 0;
    .label .text {
      font-size: 1.3rem;
    }
    .accordion-section {
      border-color: #e6e6e6;
      border-width: 2px;
    }
  }
}

.topic-bar .topic-prefix,
.topic-bar .topic-title {
  background-color: $primary-color-80 !important;
  cursor: default;
}
.topic-bar {
  &:hover {
    cursor: default;
  }
  .topic-title:empty {
    opacity: 0.2;
  }
  .topic-link {
    margin-right: 5px;
    &:empty {
      cursor: default;
    }
  }
  .check-box {
    margin-left: 2rem;
  }
}

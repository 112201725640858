@import './src/root/globals.scss';

.list-container {
  .list-answers {
    display: flex;
    position: relative;

    .answer-field {
      width: 100%;
      border-radius: 3px 0 0 3px;
    }

    .remove {
      position: absolute;
      right: -10px;
      top: 50%;
      transform: translateY(-54%);
      width: 40px;
      color: $grey-50;
      font-size: 2rem;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $error;
      }
    }
  }
}

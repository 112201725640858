@import './src/root/globals.scss';

.ReactModal__Content--after-open {
  .actions {
    margin: -2rem -4rem;
    text-align: center;

    h1 {
      font-size: 2.1rem;
      margin: 0;
      padding: 0 4rem;
      color: $primary-color;
    }

    .penalties {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      padding-top: 2rem;
      font-weight: $font-weight-bold;
      border-top: 1px solid #f0f0f0;

      input {
        cursor: pointer;
      }

      svg {
        margin-left: 10px;
        transform: translateY(0);
        opacity: 0.5;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          opacity: 1;
        }
        &[data-icon='unlock'] {
          opacity: 1;
          path {
            fill: $primary-color;
          }
        }
      }

      div:first-of-type {
        margin-right: 30px;
      }

      input {
        border-radius: 15px;
        margin: 0 10px;
        transform: translateY(0);

        &:first-of-type:checked {
          background-color: $primary-color;
          border-color: $black;
        }
        &:last-of-type:checked {
          background-color: $primary-color;
          border-color: $black;
        }
      }
    }

    .penalize {
      margin-top: 2rem;
    }

    .penaltyMessage {
      margin-top: 1em;
      width: 80%;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;

      .confirm {
        margin-top: 0;
        padding-top: 2rem;
        background: $grey-10;

        div:first-of-type {
          margin-bottom: 20px;
          font-weight: $font-weight-medium;
          font-size: 1.4rem;
        }

        button {
          width: 173px;
          background: transparent;
        }
      }

      .delete {
        &:hover {
          background-color: $danger;
          opacity: 1;
          color: $white;
        }
      }

      a,
      button {
        padding: 1.5rem 2rem;
        font-size: 1.5rem;
        border-radius: 0;
        background-color: white;
        line-height: 100%;
        display: inline-block;
        font-size: 1.4rem;
        font-weight: 600;
        color: $black;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: $primary-color;
          opacity: 1;
          color: $white;
        }
      }
    }
  }
}

@import './src/root/globals.scss';

.simple-field {
  &.question-field {
    display: block;
  }

  &.error {
    border-color: $error;
    // color: red;
  }
}

input,
textarea,
select {
  font-family: 'Open Sans';
}

.dropdown .__multi-value__label {
  white-space: normal;
}

.dropdown {
  flex: 1;
  max-width: 100%;
  text-overflow: ellipsis;
}

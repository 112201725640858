@import '../root/globals.scss';

div.notifications-container {
  width: 100%;
  height: 100%;
  margin: 1rem 0 0 0;
  padding: 0;
  border-radius: 3px;
  background-color: $white;
  font-family: $font-family;
}

div.notifications-sub-title {
  width: 100%;
  border-bottom: 1px solid $grey-5;
  font-weight: $font-weight-bold;
  color: $primary-color;
}

div.notifications-section {
  padding: 0 2.1rem;
  margin-top: 2.5rem;
  .entry {
    display: flex;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $grey-10;

    &:last-of-type {
      border-bottom: 0;
    }
  }
  div span:first-of-type {
    opacity: 0.4;
    margin-right: 2.1rem;
  }
  h4 {
    margin-bottom: 0.75rem;
  }
  textarea {
    min-height: 150px;
    padding: 1.5rem;
    line-height: 140%;
  }
}

.notifications-container {
  .controls {
    float: right;
  }
}

.notification-form {
  .notification-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }
  .send-to {
    display: flex;
    align-items: center;
    h4 {
      margin: 0;
    }

    .checkbox {
      padding-left: 1rem;
      display: flex;
      align-items: center;
    }
    input {
      margin: 1rem;
      cursor: pointer;
    }
    label {
    }
  }
}

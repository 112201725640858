%in-edit-mode {
  .ql-container {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 75%;
  }
  .ql-toolbar {
    height: 25%;
  }
}
.read-only {
  .ql-container {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
  }
  background-color: white;
}

.edit-text {
  @extend %in-edit-mode;
}
.errors {
  @extend %in-edit-mode;
  border: thin solid red;
}
.quill {
  height: inherit;
  overflow: hidden;
  max-width: 600px;
}

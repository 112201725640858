@import './src/root/globals.scss';
.resources-table {
  width: 100%;
  font-family: arial, sans-serif;
  border-collapse: separate;
  border-spacing: 7px;

  .attachment-link {
    margin-top: 0.5em;
    border-radius: 3px;
    margin-left: 0.5em;
    background-color: $primary-color;
    cursor: pointer;
    height: 3em;
    padding-top: 0.7em;
    text-align: center;
    width: 7em;
    a,
    a:hover,
    a:visited,
    a:active {
      text-decoration: none;
      color: $white;
    }
    img {
      display: block;
      height: 3em;
      width: 3em;
    }
  }
  .row {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 3em;
    margin-top: 0.5em;
    padding-top: 0.5em;
    padding-left: 0.5em;
    background-color: #f5f5f5;
  }
  .timestamp {
    font-size: 0.65em;
    opacity: 0.75;
  }
}

@import './src/root/globals.scss';

div.user-management-container {
  .whitebg {
    padding-top: 2rem;
    background: $white;

    .field {
      border-bottom: 2px solid $grey-5;
    }
  }
}

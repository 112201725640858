@import '../../root/globals.scss';

.dashboard-pane-container {
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-size: 1.3rem;
}
.penalties {
  display: inline;

  input {
    border-radius: 15px;
    margin: 0 10px;
    transform: translateY(7px);

    &:first-of-type {
      margin: 0 10px;
    }

    &:last-of-type {
      margin: 0 10px;
    }

    &:first-of-type:checked {
      background-color: $primary-color;
      border-color: $black;
    }
    &:last-of-type:checked {
      background-color: $primary-color;
      border-color: $black;
    }
  }
}

.crq-tasks {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;

  .crq-button {
    align-items: center;
    height: 70px;
    display: flex;
    width: 100%;
    margin: 0 2.5px;
    padding: 0;
    border-radius: 3px;
    background-color: #cfd0e1;
    color: $white;
    font-size: 1.3rem;
    line-height: 1.3;
    text-align: left;
    pointer-events: none;

    img {
      height: 100%;
      border-radius: 3px 0 0 3px;
      padding: 12px 10px;
      background-color: #cfd0e1;
      border-right: 1px solid $grey-10;
    }

    &.next {
      display: flex;
      pointer-events: auto;

      .text {
        margin-left: 8%;
        padding: 10px 20px;
        border-radius: 3px;
        background-color: $primary-color;
      }
    }

    &.inactive {
      pointer-events: auto;
    }

    &.active {
      background: $primary-color;
      pointer-events: auto;
      img {
        background: $primary-color;
      }
    }

    &.complete {
      background: $green;
      pointer-events: auto;
      img {
        background: $green;
      }
    }

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
    &:hover {
      opacity: 0.9;
    }
  }
}

.crq-description {
  flex-direction: column;
  justify-content: center;
  margin-left: 2rem;
  display: flex;
  font-size: 1.5rem;
  div:first-of-type {
    font-weight: 300;
  }
  div:last-of-type {
    margin-top: 3px;
    b {
      font-weight: 600;
    }
  }
}

.progress-container {
  margin: 0.5rem 0;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgb(228, 228, 228);
  width: 100%;
  text-align: center;
  .forced {
    background: orange;
    color: $white;
    margin: 0 auto;
    font-size: 1.2rem;
  }
  .crq-next-stage-btn {
    background: $primary-color;
    color: $white;
    margin: 0 auto;
    font-size: 1.2rem;

    &:disabled {
      opacity: 0.3;
      cursor: initial;
    }
  }
}

.dashboard-papers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
  background-color: white;
  width: 100%;
  border-radius: 3px;
  height: 70px;

  > div {
    height: 100%;
  }
}

.papers-list {
  display: flex;
  max-width: 550px;
  align-items: center;
  flex: 0 0 auto;
  overflow-x: scroll;

  scrollbar-width: thin;
  scrollbar-color: $grey-25 white;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $grey-25;
    border-radius: 20px;
    border: 7px solid white;
  }

  &.papers-list--groups {
    max-width: 790px;
  }

  > div {
    opacity: 0.6;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    flex: 0 0 auto;
    width: 90px;
    margin-top: 1.5rem;
    &:hover {
      opacity: 1;
    }
  }

  > div.selected {
    background-color: $primary-color;
    opacity: 1;
  }
}

.papers-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.dashboard-papers-controls {
  display: flex;
  width: 220px;
  flex: 0 1 auto;
  margin-left: auto;
  align-items: center;
  border-left: 1px solid $grey-10;

  > button {
    flex: 50%;
    font-size: 1.2rem;
  }

  .dashboard-add-paper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 1rem;
    font-weight: 600;
    color: $grey;
    background-color: $white;
    border: 1px dashed $grey;
    border-radius: 5px;
    cursor: pointer;
    padding: 1rem;

    &:disabled {
      opacity: 0.4;
      cursor: initial;
    }
  }
}

.dashboard-paper {
  position: relative;
  background-color: $primary-color;
  color: $white;
  border-radius: 3px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  text-align: center;

  .dashboard-paper-inner {
    width: 100%;
    padding: 1rem;
  }

  &:hover .dashboard-paper-delete {
    position: absolute;
    display: block;
    z-index: 7;
    width: 19px;
    height: 19px;
    top: -0.5rem;
    right: -0.5rem;
    background-color: $white;
    border: 1px solid $primary-color;
    border-radius: 100%;
    transition: background-color 0.3s ease-in-out;

    &::after {
      content: 'x';
      font-weight: $font-weight-bold;
      color: $primary-color;
      display: block;
      position: absolute;
      right: 5px;
      top: -2px;
      font-size: 1.4rem;
      transition: color 0.3s ease-in-out;
    }
    &:hover {
      background: $error;
      &::after {
        color: $white;
      }
    }
  }
}

.dashboard-new-round {
  height: 100%;
  padding: 1.15rem 1rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $white;
  font-weight: $font-weight-regular;
  line-height: 120%;
  background-color: $grey-40;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &:disabled {
    opacity: 0.4;
    cursor: initial;
    color: $black;
  }
}

.dashboard-info-container {
  flex-direction: row;
  display: flex;
  width: 100%;
  margin-top: 1rem;
  background-color: $white;
  padding: 0 1rem;
  border-radius: 3px;
}

.user-dashboard-info-container {
  flex-direction: row;
  display: flex;
  width: 100%;
  .rc-calendar {
    padding: 1.5rem;
    margin-top: 1rem;
    margin-right: 1rem;
  }
  .notifications-container {
    max-height: 350px;
    overflow: auto;
    .notifications-sub-title {
      width: 100%;
      padding: 2rem 1.5rem;
    }
  }
}

.article-sub-title {
  margin: 0 20px 8px 20px;
  font-weight: $font-weight-bold;
  font-size: 1.4rem;
}

.dashboard-articles {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  flex: 0.75;
}

.dashboard-article {
  margin: 1px 20px;
  padding: 12px 10px;
  border-radius: 2px;
  color: $white;
  background-color: $primary-color;
  font-weight: $font-weight-thin;
  text-align: left;
  border-radius: 3px;

  svg {
    margin-right: 15px;
  }
}

.dashboard-notifications-container {
  display: flex;
  width: 100%;
  height: 405px;

  .dashboard-notifications {
    background-color: $white;
    height: 100%;
    width: 50%;
    margin: 10px 5px 0 0;
  }

  .notifications-sub-title {
    font-weight: $font-weight-bold;
    font-size: 1.4rem;
    padding: 2.1rem 2.1rem 0 2.1rem;
    border: 0;
    color: $black;
  }
}

.dashboard-deadlines {
  background-color: #fbfbfb;
  height: 100%;
  width: 50%;
  margin: 1rem 0 0 1rem;
  border-radius: 3px;
}

.deadlines-sub-title {
  font-weight: $font-weight-bold;
  font-size: 1.4rem;
  padding: 1.5rem;
  color: $secondary-color;
  background: $white;
  border-radius: 3px 3px 0 0;
}

.crq-status-bar {
  width: 100%;
  padding: 2rem;
  border-radius: 3px;
  background-color: $secondary-color;
  color: $white;
  font-size: 1.4rem;
  text-align: center;
  line-height: 3.4rem;
}

.round-date {
  display: flex;
  flex-direction: column;
  padding: 1rem !important;
  margin: 0 1rem;
  border-right: 1px solid $grey-10;
  justify-content: center;
}

.date-time {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem;
  border-radius: 0 0 4px 4px;
  background-color: $primary-color;
  border: 2px solid white;

  > div {
    font-weight: $font-weight-bold;
    color: $white;
    padding-right: 10px;
  }

  > input {
    font-weight: $font-weight-bold;
    background-color: $primary-color !important;
    color: $white;
  }
  .react-datepicker-wrapper {
    margin-right: 2rem;
    .react-datepicker__input-container {
      input {
        font-weight: $font-weight-bold;
        background-color: $primary-color;
        color: $white;
        border-color: rgba(255, 255, 255, 0.5) !important;
        &:focus {
          border-color: rgba(255, 255, 255, 1) !important;
        }
      }
    }
  }

  .react-datepicker-popper {
    z-index: 4;
  }
  .react-datepicker__tab-loop {
    padding-right: 0;
  }
}

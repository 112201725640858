@import '../../root/globals.scss';

@media screen and (max-width: 1024px) {
  body,
  html {
    overflow: hidden;
  }
}

div.topbar {
  font-family: $font-family;
  height: 90px;
  color: $white;
  background-color: $primary-color;
  display: flex;

  .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
    a {
      margin-top: 5px;
    }
    a:active {
      color: $white;
    }
  }

  .user-container {
    display: flex;
    font-size: 1.3rem;
    align-items: center;

    .simple-button.muted {
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    &::after {
      position: fixed;
      content: 'The eClub is not designed for mobile devices. Please visit your account on a laptop or desktop computer for the best experience.';
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
      width: 100vw;
      height: calc(100vh - 90px);
      background: $primary-color;
      top: 90px;
      left: 0;
      box-sizing: border-box;
      padding: 0 5rem;
      z-index: 99999;
    }
    .user-container div {
      display: none;
    }
  }
}

.logout {
  background: none;
  cursor: pointer;
  padding: 0;
  color: $white;
  margin-left: 3rem;

  &:hover {
    color: $primary-color-60;
  }
}

.block-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
  background-color: $danger;
  color: $white;
  font-weight: $font-weight-medium;
  > div:first-of-type {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  > div:last-of-type {
    font-size: 1.4rem;
    font-weight: $font-weight-regular;
    a {
      color: $white;
      font-weight: $font-weight-bold;
      text-decoration: underline;
    }
  }
}

@import './src/root/globals.scss';
.response .button-row {
  margin-top: 0;
}
.response.answer .button-row {
  margin-bottom: 1.5rem;
}
.button-row {
  display: flex;
  padding: 0 2rem 0 0;
  margin-top: 2rem;

  .button-container {
    margin-top: 0;
  }

  button:last-of-type {
    margin-left: 1rem;
  }
  button:first-of-type {
    margin-left: 0;
  }

  &.right {
    justify-content: flex-end;
  }
  &.spaced {
    justify-content: space-between;
  }
  &.white {
    background-color: $white;
  }
  &.pad {
    padding: 1rem;
  }
  &.controls {
    padding: 1rem 0 0 0;
    align-items: center;
    div {
      padding: 0 10px 3px 0;
    }
  }
  &.date {
    margin: 0;
    margin-left: auto;
    padding-right: 0;
  }
}

.top-row .button-row {
  padding-right: 0;
}

.response-meta .button-row {
  margin-top: 2rem;
  margin-bottom: -5px;
}

.response-meta.confirmed .button-row {
  margin-top: 0;
}
